import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
declare const gigya: any;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  expirationTime: any;
  accessToken: any;
  constructor(private http: HttpClient) {}

  getProfileDetails() {
    const profile: any = sessionStorage?.getItem('profile');
    try {
      return JSON.parse(profile);
    } catch (error) {
      return {};
    }
  }

  setAuthorize(params: any) {
    sessionStorage.setItem('isAuthorized', params);
  }

  redirectToURL(path: string) {
    window.location.href = window.location.origin + `/${path}`;
  }

  getToken(code: any) {
    const {
      issuer_url,
      apiKey,
      grant_type,
      redirect_uri,
      clientId,
      clientSecret,
    } = environment;
    const secretCode = btoa(`${clientId}:${clientSecret}`);
    const headers = new HttpHeaders({
      Authorization: `Basic ${secretCode}`,
    });

    this.http
      .post(
        `${issuer_url}/${apiKey}/token?grant_type=${grant_type}&redirect_uri=${redirect_uri}&code=${code}`,
        {},
        {
          headers: headers,
        }
      )
      .subscribe((data: any) => {
        if (data?.access_token) {
          this.saveTokens(data);
          this.redirectToURL('');
        }
      });
  }

  saveTokens(tokendata: any) {
    sessionStorage.setItem('accessToken', tokendata.access_token);
    sessionStorage.setItem('refreshToken', tokendata.refresh_token);
  }

  logOut() {
    gigya.accounts.logout({
      callback: (response: any) => {
        if (response.errorCode === 0) {
          sessionStorage.clear();
          this.redirectToURL('login');
        }
      },
    });
  }
}
